import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  wrapper: css<{ isMobile: boolean }>`
    display: flex;
    height: 44px;
    flex: 1;
    justify-content: ${({ isMobile = false }) => (isMobile ? 'center' : 'flex-start')};
    align-items: ${({ isMobile = false }) => (isMobile ? 'center' : 'flex-start')};
    margin: 0;
  `,
  logoImage: css<{ isMobile: boolean }>`
    margin-right: 0;
    width: 137px;
    height: 44px;
  `
};
