import React from 'react';

import { useAuthentication, useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { Fan } from '@gf/cross-platform-lib/models';
import { ProfileBody, ProfileFooter, ProfileHeader } from './components';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { ScrollView, View, Text } from 'react-native';
import { LayoutContent } from '../..';
import { AppInfoText, PlayOnBranding } from '@gf/cross-platform-lib/components';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers/LaunchDarkly/LaunchDarklyContext';

export const Profile = ({ hasLayout = true }: { hasLayout?: boolean }) => {
  const { isFanSignedIn } = useAuthentication();
  const email = isFanSignedIn() ? Fan.getInstance().email : '';
  const { isMobile, isNativeApp } = useMediaQuery();

  // TODO-usercontext: Remove traces of this code when user context QA'd and greenlit
  const launchDarklyContext = useLaunchDarklyContext();
  const { variation: isTargetedUser } = launchDarklyContext.features['user-context-experiment'];
  const userContextIndicator = () => <Text>User Context: Success</Text>;
  ////////////////////////////

  const renderMobileProfile = () => (
    <ScrollView showsVerticalScrollIndicator={false}>
      <MobileContainer>
        <PaddingWrapper>
          <ProfileBody email={email} />
        </PaddingWrapper>
        {isNativeApp ? (
          <>
            <PlayOnBranding marginTop='16px' />
            <PaddingWrapper>
              <AppInfoText />
            </PaddingWrapper>
          </>
        ) : (
          <ProfileFooter />
        )}
      </MobileContainer>
      {isTargetedUser && userContextIndicator()}
    </ScrollView>
  );

  return isMobile || isNativeApp ? (
    <>
      {hasLayout ? (
        <LayoutContent title='Profile' showingTitle={true} hideFooter={true}>
          {renderMobileProfile()}
        </LayoutContent>
      ) : (
        renderMobileProfile()
      )}
    </>
  ) : (
    <ScrollView showsVerticalScrollIndicator={false}>
      <WebContainer>
        <PaddingWrapper>
          <ProfileHeader />
        </PaddingWrapper>
        <WebBodyFooter>
          <ProfileBody email={email} />
        </WebBodyFooter>
        <ProfileFooter />
      </WebContainer>
      {isTargetedUser && userContextIndicator()}
    </ScrollView>
  );
};

const MobileContainer = styled(View)`
  ${styles.mobileContainer}
`;
const WebContainer = styled(View)`
  ${styles.webContainer}
`;
const WebBodyFooter = styled(View)`
  ${styles.webBodyFooter}
`;
const PaddingWrapper = styled(View)`
  ${styles.paddingWrapper}
`;

export default Profile;
