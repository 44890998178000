import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  header: css`
    gap: 8px;
  `,
  icons: css`
    flex-direction: row;
    margin-top: 24px;
  `,
  marginRightTwentyFour: css`
    margin-right: 24px;
  `,
  paddingWrapper: css<{ isMobileApp: boolean }>`
    padding-horizontal: ${({ isMobileApp }) => (isMobileApp ? '32px' : '40px')};
  `
};
