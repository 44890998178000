import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const CartIcon = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M13.6069 3.22307H4.20568L4.06375 2.55596C3.89342 1.76583 3.18372 1.19333 2.37464 1.19333H1.37633C0.983619 1.19333 0.666626 1.51032 0.666626 1.90303C0.666626 2.29101 0.983619 2.61274 1.37633 2.61274H2.37465C2.51658 2.61274 2.64434 2.71211 2.67746 2.85405L4.49432 11.3327L3.96438 11.8626C3.46759 12.3594 3.32092 13.0975 3.59061 13.7457C3.85557 14.3939 4.48484 14.8102 5.18508 14.8102H11.7097C12.1024 14.8102 12.4194 14.4932 12.4194 14.1005C12.4194 13.7126 12.1024 13.3908 11.7097 13.3908H5.18508C5.00529 13.3908 4.9296 13.2725 4.90121 13.2016C4.87282 13.1306 4.8397 12.9934 4.96745 12.8704L5.56358 12.2695H12.1166C12.8972 12.2695 13.5833 11.7443 13.782 10.9873L15.2724 5.39479C15.4143 4.87435 15.3055 4.33023 14.9743 3.89966C14.6478 3.46911 14.1463 3.22307 13.6069 3.22307Z'
      fill='#323232'
    />
  </Svg>
);
export default CartIcon;
