import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './HeaderBurger.styles';
import { GFImage } from '@gf/cross-platform-lib/components';
import { TouchableOpacity } from 'react-native';
import { PROFILE, useDrawerContext } from '@gf/cross-platform-lib/providers';

const menuIconSrc = 'menu-white.png';

const Burger = styled(TouchableOpacity)`
  ${styles.burger}
`;

export const HeaderBurger = () => {
  const { toggleDrawer } = useDrawerContext();

  return (
    <Burger onPress={() => toggleDrawer(PROFILE)} activeOpacity={1}>
      <GFImage width={35} height={40} name={menuIconSrc} alt='Menu' />
    </Burger>
  );
};
