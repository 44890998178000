import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { GFImage, StyledText } from '@gf/cross-platform-lib/components';
import { TouchableOpacity, View } from 'react-native';
import { useAuthentication, useRouter, useMediaQuery, useParams, useNavigate } from '@gf/cross-platform-lib/hooks';
import { HeaderNav } from '../HeaderNav';
import { styles } from './Header.styles';
import { HeaderBurger } from '../HeaderBurger';
import { Logo } from './Logo';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { getHeaderTestId, testProperties } from '@gf/cross-platform-lib/utils';
import { APP_HEADER_SIZE } from '@gf/cross-platform-lib/providers';

interface HeaderProps {
  showingTitle?: boolean;
  title?: string;
  actions?: React.ReactElement;
}

type HeaderURLParams = {
  redirectHomePage: boolean;
  redirectMyTicketPage: boolean;
};

const GoBackButton = ({ goBack }: { goBack: () => void }) => (
  <TouchableOpacity onPress={goBack} testID='go-back-button'>
    <GFImage name={'prev-arrow-white.png'} width={32} height={44} />
  </TouchableOpacity>
);

export const Header = ({ showingTitle, title, actions }: HeaderProps) => {
  const navigate = useNavigate(false);
  const { isMobile, isMobileApp } = useMediaQuery();
  const { redirectHomePage = false, redirectMyTicketPage = false } = (useParams() as HeaderURLParams) || {};

  const { signOut, isFanSignedIn } = useAuthentication();
  const { goBack, canGoBack, currentRoute } = useRouter();

  const backToHome = () => navigate(GOFAN_APP_PAGES.home.path);
  const backToMyTicket = () => navigate(GOFAN_APP_PAGES.myTickets.path);

  const handleGoBack = () => {
    if (redirectHomePage || [GOFAN_APP_PAGES.myTickets.path].includes(currentRoute)) return backToHome();
    if (redirectMyTicketPage) return backToMyTicket();
    return goBack();
  };

  const mobileAppHeader = () => {
    return (
      <>
        <Leading isMobile>{canGoBack() && <GoBackButton goBack={handleGoBack} />}</Leading>
        {showingTitle ? (
          <StyledText typeToken='heading03SemiBold' color='textInverse' style={{ flex: 1, textAlign: 'center' }}>
            {title}
          </StyledText>
        ) : (
          <Logo isMobile />
        )}
        {actions ? <ActionButtonsWrapper>{actions}</ActionButtonsWrapper> : null}
      </>
    );
  };

  const webAppHeader = () => {
    return (
      <header style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Leading>
          <HeaderBurger />
        </Leading>
        {showingTitle ? (
          <StyledText typeToken='heading03SemiBold' color='textInverse' style={{ flex: 1, textAlign: 'center' }}>
            {title}
          </StyledText>
        ) : (
          <Logo />
        )}
        <HeaderNav signOut={signOut} isFanSignedIn={isFanSignedIn && isFanSignedIn()} />
      </header>
    );
  };
  return (
    <Wrapper {...testProperties(getHeaderTestId(currentRoute))} isMobile={isMobile} headerSize={APP_HEADER_SIZE}>
      {isMobileApp ? mobileAppHeader() : webAppHeader()}
    </Wrapper>
  );
};

const Wrapper = styled(View)`
  ${styles.wrapper}
`;

const Leading = styled(View)`
  ${styles.leading}
`;

const ActionButtonsWrapper = styled(View)`
  ${styles.actionButtonsWrapper}
`;
