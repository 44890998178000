import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  expandableTitle: css`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
  `,
  iconWrapper: css`
    align-items: center;
    justify-content: center;
  `
};
