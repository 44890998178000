import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const MenuIconLegacy = (props: SvgProps) => (
  // @ts-ignore
  <Svg width={24} height={18} xmlns='http://www.w3.org/2000/svg' {...props}>
    <G stroke='#A8A8A8' strokeWidth={2.5} fill='none' fillRule='evenodd' strokeLinecap='round'>
      <Path d='M1.5 9h21M1.5 2h21M1.5 16h21' />
    </G>
  </Svg>
);
