import React from 'react';

import { AppInfoText, ExternalLink, GFImage, StyledText, PlayOnBranding } from '@gf/cross-platform-lib/components';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { View } from 'react-native';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';

export const DownloadGoFan = () => {
  const { isMobileApp } = useMediaQuery();
  return (
    <>
      <PaddingWrapper isMobileApp={isMobileApp}>
        <Header gap={8}>
          <StyledText typeToken='heading04'>Download GoFan</StyledText>
          <StyledText typeToken='label03'>
            Download the GoFan app for free to unlock your fan analytics, access your tickets instantly, setup
            notifications when events start, & skip finding your tickets in emails.
          </StyledText>
        </Header>

        <Icons>
          <MarginRightTwentyFour>
            <ExternalLink
              href={'https://itunes.apple.com/us/app/gofan-high-school-tickets/id1049091284'}
              title='Get the GoFan app'
            >
              <GFImage name={'apple-badge.png'} width={111} height={37} alt='App store icon' />
            </ExternalLink>
          </MarginRightTwentyFour>
          <ExternalLink
            href={'https://play.google.com/store/apps/details?id=co.gofan.fan.iron'}
            title='Get the GoFan app'
          >
            <GFImage name={'google-play-badge.png'} width={124} height={37} alt='Google Play store icon' />
          </ExternalLink>
        </Icons>
      </PaddingWrapper>
      <PlayOnBranding />
      <PaddingWrapper isMobileApp={isMobileApp}>
        <AppInfoText />
      </PaddingWrapper>
    </>
  );
};
const Header = styled(View)`
  ${styles.header}
`;
const Icons = styled(View)`
  ${styles.icons}
`;
const MarginRightTwentyFour = styled(View)`
  ${styles.marginRightTwentyFour}
`;

const PaddingWrapper = styled(View)`
  ${styles.paddingWrapper}
`;

export default DownloadGoFan;
