import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const TicketsTabIcon = (props: SvgProps) => (
  // @ts-ignore
  <Svg width={20.26} height={20.26} viewBox='0 0 21 21' {...props}>
    <Path
      d='M12.905 2.59a.75.75 0 0 0-1.06 0l-9.879 9.88a.75.75 0 0 0 0 1.06l1.068 1.068a2.75 2.75 0 0 1 3.742 3.742l1.069 1.069a.75.75 0 0 0 1.06 0l9.879-9.879a.75.75 0 0 0 0-1.06L17.716 7.4a2.75 2.75 0 0 1-3.742-3.742L12.904 2.59Zm-2.121-1.06a2.25 2.25 0 0 1 3.182 0l1.171 1.172c.511.51.42 1.227.162 1.66a1.25 1.25 0 0 0 1.713 1.713c.433-.257 1.15-.349 1.661.162l1.172 1.172a2.25 2.25 0 0 1 0 3.182l-9.879 9.878a2.25 2.25 0 0 1-3.182 0l-1.172-1.171c-.51-.51-.419-1.228-.161-1.66a1.25 1.25 0 0 0-1.713-1.713c-.434.257-1.15.348-1.661-.163L.905 14.591a2.25 2.25 0 0 1 0-3.182l9.879-9.879Z'
      fill={props.color}
      fillRule='nonzero'
    />
  </Svg>
);
