import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  wrapper: css`
    color: white;
    align-items: center;
    display: flex;
    padding-right: 16px;
    flex: 1;
    flex-grow: 0;
    flex-basis: auto;
    justify-content: flex-end;
    flex-direction: row;
    height: 20px;
  `,
  navItem: css`
    white-space: nowrap;
    padding-left: 16px;
    height: 20px;
  `,
  navText: css`
    font-family: Rubik-Regular;
    font-weight: normal;
    font-size: 14px;
    cursor: pointer;
    color: #ffffff;
    height: 20px;
    letter-spacing: 0.16px;
    line-height: 20px;
  `
};
