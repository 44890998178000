import { QueryClient, useQueries, useQuery } from '@tanstack/react-query';
import { StorableType } from '@gf/cross-platform-lib/utils/config/config';
import { fetchSchool, getAllSchools, fetchAllStates, getAllSchoolsForSchoolDistrict } from '../apis';
import {
  SchoolQueryParams,
  School,
  GroupSchoolsByState,
  RetrySettings,
  SafeFetchResponse
} from '@gf/cross-platform-lib/interfaces';
import { buildQueryKey, getQuerySSR } from '../utils';

const defaultQueryConfigs = {
  staleTime: 1000 * 60 * 10, // 10 minutes
  refetchOnWindowFocus: false
};

const getUseFetchSchoolQueryOptions = (
  schoolId: string,
  queryParams: SchoolQueryParams = { gofanPageEnabled: false },
  redirect404: boolean = true,
  retrySettings?: RetrySettings
) => {
  return {
    ...retrySettings,
    queryKey: buildQueryKey([StorableType.School, schoolId, queryParams]),
    queryFn: () => fetchSchool(schoolId, queryParams, redirect404)
  };
};

export const useFetchSchool = (
  schoolId: string,
  queryParams: SchoolQueryParams = { gofanPageEnabled: false },
  redirect404: boolean = false,
  retrySettings?: RetrySettings
) => {
  const opts = getUseFetchSchoolQueryOptions(schoolId, queryParams, redirect404, retrySettings);
  return useQuery({
    ...opts,
    ...defaultQueryConfigs,
    enabled: !!schoolId
  });
};

export const fetchSchoolSSR = async (
  queryClient: QueryClient,
  schoolId: string,
  queryParams: SchoolQueryParams = { gofanPageEnabled: false },
  redirect404: boolean = true
) => {
  const { queryKey, queryFn } = getUseFetchSchoolQueryOptions(schoolId, queryParams, redirect404);
  return getQuerySSR<SafeFetchResponse<School>>(queryKey, queryFn, queryClient);
};

export const useFetchSchools = (
  schoolIds: string[],
  queryParams: SchoolQueryParams = { gofanPageEnabled: false },
  redirect404: boolean = false
) => {
  return useQueries({
    queries: schoolIds.map(id => {
      return {
        queryKey: buildQueryKey([StorableType.School, id, queryParams]),
        queryFn: () => fetchSchool(id, queryParams, redirect404),
        ...defaultQueryConfigs,
        enabled: !!id
      };
    })
  }).filter(resp => !resp.isFetching && resp.data);
};

export const useGroupAllSchoolsByStateQuery = () => {
  return useQuery({
    queryKey: buildQueryKey([StorableType.School, 'all-schools']),
    queryFn: () =>
      getAllSchools().then(res =>
        res.reduce(
          (result: GroupSchoolsByState, item) => ({
            ...result,
            [item.state]: [...(result[item.state] || []), item]
          }),
          {}
        )
      )
  });
};

export const useFetchAllStatesQuery = () => {
  return useQuery({
    queryKey: buildQueryKey(['fetch-all-states']),
    queryFn: () => fetchAllStates(),
    staleTime: 1000 * 60 * 60 * 24 * 30,
    refetchOnWindowFocus: false
  });
};

const getUseGetAllSchoolsQueryOptions = () => {
  return {
    queryKey: buildQueryKey([StorableType.School, 'all-schools']),
    queryFn: () => getAllSchools()
  };
};

export const getAllSchoolsSSR = async (queryClient: QueryClient) => {
  const { queryKey, queryFn } = getUseGetAllSchoolsQueryOptions();
  return getQuerySSR<School[]>(queryKey, queryFn, queryClient);
};

export const useGetAllSchoolsForSchoolDistrict = (schoolDistrictHuddleId: string, isSchoolDistrict: boolean) => {
  return useQuery({
    queryKey: buildQueryKey([StorableType.School, 'school-district-schools', schoolDistrictHuddleId]),
    queryFn: () => getAllSchoolsForSchoolDistrict(schoolDistrictHuddleId),
    staleTime: 1000 * 60 * 60 * 12,
    enabled: isSchoolDistrict
  }).data?.data;
};
