import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  burger: css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  `
};
