import { DownloadGoFan } from '@gf/cross-platform-lib/components/DownloadGoFan';
import React from 'react';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { View } from 'react-native';

interface ProfileFooter {}
export const ProfileFooter = ({}: ProfileFooter) => {
  return (
    <Container>
      <DownloadGoFan />
    </Container>
  );
};

const Container = styled(View)`
  ${styles.container}
`;
export default ProfileFooter;
