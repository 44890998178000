import React, { ReactElement } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';

interface ExpandableCollapsableProps {
  title: ReactElement;
  expandIcon?: ReactElement;
  collapseIcon?: ReactElement;
  expandableContent: ReactElement;
  expandable?: boolean;
}

export const ExpandableCollapsable = ({
  title,
  expandIcon,
  collapseIcon,
  expandableContent,
  expandable
}: ExpandableCollapsableProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(!expandable ?? false);

  return (
    <>
      <TouchableOpacity
        onPress={() => expandable && setExpanded(prevState => !prevState)}
        testID='test-expandable-title'
      >
        <ExpandableTitle>
          {title}
          <IconWrapper>{expanded ? collapseIcon : expandIcon}</IconWrapper>
        </ExpandableTitle>
      </TouchableOpacity>
      {expanded && expandableContent}
    </>
  );
};

const ExpandableTitle = styled(View)`
  ${styles.expandableTitle};
`;
const IconWrapper = styled(View)`
  ${styles.iconWrapper};
`;

export default ExpandableCollapsable;
