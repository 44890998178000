import { View } from 'react-native';
import styled from '@gf/cross-platform-lib/styled-components';

export interface HorizontalRowProps {
  color?: string;
  marginBottom?: number;
  marginTop?: number;
  flex?: number;
}

export const HorizontalRow = styled(View)<HorizontalRowProps>(
  ({ color = '#d3d3d3', marginBottom = 32, marginTop = 32, flex }: HorizontalRowProps) => ({
    borderColor: color,
    borderStyle: 'solid',
    borderBottomWidth: 0.5,
    marginBottom,
    marginTop,
    width: '100%',
    flex: flex
  })
);
