import React from 'react';
import ProfileAccordionHeader from './ProfileAccordionHeader';
import ProfileAccordionExpandable from './ProfileAccordionExpandable';
import { ExpandableCollapsable } from '@gf/cross-platform-lib/components/ExpandableCollapsable';
import { ChevronDown, ChevronUp } from '@gf/cross-platform-lib/components/Icons/Carbonicons/collections';
import { View } from 'react-native';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { ProfileAccordionSubHeaderAction } from '../../profileInterfaces';

interface ProfileAccordionItemProps {
  header: string;
  subHeadersToActions: ProfileAccordionSubHeaderAction[];
  expandable: boolean;
}

export const ProfileAccordionItem = ({ header, subHeadersToActions, expandable }: ProfileAccordionItemProps) => {
  return (
    <>
      <ExpandableCollapsable
        title={<ProfileAccordionHeader headerName={header} />}
        expandableContent={<ProfileAccordionExpandable subHeadersToActions={subHeadersToActions} />}
        expandable={expandable}
        expandIcon={
          expandable ? (
            <MarginThirtyTwoTop>
              <ChevronDown height={24} width={24} fill={'#000000'} />
            </MarginThirtyTwoTop>
          ) : undefined
        }
        collapseIcon={
          expandable ? (
            <MarginThirtyTwoTop>
              <ChevronUp height={24} width={24} fill={'#000000'} />
            </MarginThirtyTwoTop>
          ) : undefined
        }
      />
    </>
  );
};

const MarginThirtyTwoTop = styled(View)`
  ${styles.marginThirtyTwoTop}
`;

export default ProfileAccordionItem;
