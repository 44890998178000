import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  info: css`
    align-items: center;
    align-self: center;
    gap: 4px;
    margin-top: 24px;
  `
};
