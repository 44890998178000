import React from 'react';
import { View } from 'react-native';
import { GFImage } from '@gf/cross-platform-lib/components';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';

const Wrapper = styled(View)`
  ${styles.wrapper}
`;

interface SchoolLogoProps {
  logoSrc: string;
  logoHeight?: number;
  logoWidth?: number;
  logoPadding?: number;
  schoolColor?: string;
  padding?: number;
}

export const SchoolLogo = ({
  logoSrc,
  logoHeight = 40,
  logoWidth = 40,
  logoPadding = 16,
  schoolColor = 'white',
  padding = 12
}: SchoolLogoProps) => {
  return (
    <Wrapper
      schoolColor={schoolColor}
      style={{ width: logoWidth + logoPadding, height: logoHeight + logoPadding }}
      padding={padding}
    >
      <GFImage src={logoSrc} width={logoWidth} height={logoHeight} alt={'school logo'} fallbackImg={'gofan-icon.png'} />
    </Wrapper>
  );
};

export default SchoolLogo;
