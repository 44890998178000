import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const MenuIcon = (props: SvgProps) => (
  // @ts-ignore
  <Svg width={20} height={20} {...props}>
    <Path d='M14.63 12a2.249 2.249 0 0 1 2.248 2.249v.575c0 .894-.32 1.76-.901 2.438-1.57 1.834-3.957 2.739-7.102 2.739-3.146 0-5.532-.905-7.098-2.74a3.75 3.75 0 0 1-.898-2.435v-.577a2.249 2.249 0 0 1 2.248-2.25H14.63Zm0 1.5H3.126a.749.749 0 0 0-.748.749v.577c0 .536.19 1.054.539 1.461 1.253 1.468 3.219 2.214 5.957 2.214s4.706-.746 5.962-2.214a2.25 2.25 0 0 0 .541-1.463v-.575a.749.749 0 0 0-.749-.75ZM8.874.004a5 5 0 1 1 0 10 5 5 0 0 1 0-10Zm0 1.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z' />
  </Svg>
);
