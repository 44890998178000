import React from 'react';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { View } from 'react-native';
import useGetProfileMenuInfo from '../../hooks/useGetProfileMenuInfo';
import ProfileAccordionItem from './ProfileAccordionItem';
import { AdBanner } from '@gf/cross-platform-lib/components';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers';
import { AdPageType } from '@gf/cross-platform-lib/components/AdBanner/util';

export const ProfileAccordionMenu = () => {
  const headersToSubHeadersActions = useGetProfileMenuInfo();
  const launchDarklyContext = useLaunchDarklyContext();
  const { variation: adsEnabled = false } = launchDarklyContext?.features['ads-enabled'] || {};

  return (
    <ProfileAccordion>
      {Array.from(headersToSubHeadersActions.entries()).map(headerToSubHeadersActions => {
        const header = headerToSubHeadersActions[0];
        const subHeadersToActions = headerToSubHeadersActions[1].subHeaderAction;
        const expandable = headerToSubHeadersActions[1].expandable;
        return (
          <View key={header}>
            {adsEnabled && header === 'More resources' && <AdBanner page={AdPageType.PROFILE} />}
            <ProfileAccordionItem header={header} subHeadersToActions={subHeadersToActions} expandable={expandable} />
          </View>
        );
      })}
    </ProfileAccordion>
  );
};

const ProfileAccordion = styled(View)`
  ${styles.profileAccordion};
`;

export default ProfileAccordionMenu;
