import React, { Suspense, useEffect, LazyExoticComponent, ComponentType } from 'react';
import get from 'lodash/get';
import { View, Platform } from 'react-native';

import Head from 'next/head';

import {
  useMediaQuery,
  useNavigationFocused,
  useRouter,
  useTracking,
  useFocusEffect,
  useLaunchDarklyContextSync
} from '@gf/cross-platform-lib/hooks';
import styled from '@gf/cross-platform-lib/styled-components';
import { PageFooter } from '../components';
import { useLocation, useGlobalLoadingContext, DrawerContent, PROFILE } from '@gf/cross-platform-lib/providers';
import { pathsToPageConfig } from '@gf/cross-platform-lib/constants';
import { Fan } from '@gf/cross-platform-lib/models';
import { Header } from './Header';
import { Profile } from './profile';
import { APP_HEADER_SIZE } from '@gf/cross-platform-lib/providers';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers';
import { MobileFooterLegacy } from './Footer/Legacy/MobileFooterLegacy';

let MobileFooter: LazyExoticComponent<ComponentType> | ComponentType;

if (Platform.OS === 'web') {
  MobileFooter = React.lazy(() => import('./Footer').then(module => ({ default: module.MobileFooter })));
} else {
  MobileFooter = require('./Footer').MobileFooter;
}

interface LayoutProps {
  children: React.ReactNode;
  title?: string;
  showingTitle?: boolean;
  actions?: React.ReactElement;
  hideFooter?: boolean;
  backgroundColor?: string;
}

interface MainViewProps {
  isMobileApp?: boolean;
  appHeaderSize: number;
  backgroundColor?: string;
}

export const HeaderFallback = () => {
  const { isMobileApp } = useMediaQuery();
  return (
    <View
      style={{
        backgroundColor: '#323232',
        height: isMobileApp ? 44 : 48
      }}
    />
  );
};

export const LayoutContent = ({ children, title, showingTitle, actions, hideFooter, backgroundColor }: LayoutProps) => {
  const { isMobileApp, isWebApp } = useMediaQuery();
  const { checkPermission } = useLocation();
  const { isFocused } = useNavigationFocused();
  const { currentRoute } = useRouter();
  const { identifyPageView } = useTracking();
  const { showGlobalLoading, loading } = useGlobalLoadingContext();
  const hideMobileFooter = get(pathsToPageConfig, `${currentRoute}.hideMobileFooter`, false);
  const launchDarklyContext = useLaunchDarklyContext();
  const teamsInGoFanFlag = launchDarklyContext.features['teams-in-gofan'].variation as boolean;
  const hasTitle = isMobileApp && showingTitle;
  const hasMobileFooter = isMobileApp && !hideMobileFooter;
  const fan = Fan.getInstance();

  useLaunchDarklyContextSync([
    {
      kind: 'user',
      key: fan.firebaseUser?.uid ?? 'anonymous-user',
      attributes: {
        anonymous: !fan.firebaseUser?.uid,
        name: fan.email ?? '',
        fanEmail: fan.email ?? '',
        fanFirstName: fan.firstName ?? '',
        fanLastName: fan.lastName ?? ''
      }
    }
  ]);

  const renderFooter = () => {
    if (hasMobileFooter) {
      return teamsInGoFanFlag ? <MobileFooter /> : <MobileFooterLegacy />;
    }
    if (isMobileApp) return null;
    return <PageFooter />;
  };

  useEffect(() => {
    checkPermission();
    identifyPageView(Fan.getInstance());
  }, []);

  useFocusEffect(() => {
    return () => {
      if (loading) {
        showGlobalLoading(false);
      }
    };
  });

  return (
    <>
      <DrawerContent drawerId={PROFILE} content={<Profile />} position='left' drawerSize={400} />
      {isWebApp && title && (
        <Head>
          <title> {title} </title>
        </Head>
      )}
      <Suspense fallback={<HeaderFallback />}>
        <Header title={title} showingTitle={hasTitle} actions={actions} />
      </Suspense>
      <Main appHeaderSize={APP_HEADER_SIZE} isMobileApp={isMobileApp} backgroundColor={backgroundColor}>
        {isFocused && children}
      </Main>
      <Suspense>{!hideFooter && renderFooter()}</Suspense>
    </>
  );
};

const Main = styled.View`
  margin-top: ${({ appHeaderSize }: MainViewProps) => appHeaderSize};
  background-color: ${({ backgroundColor }: MainViewProps) => (backgroundColor ? backgroundColor : '#fff')};
  flex: ${({ isMobileApp }: MainViewProps) => (isMobileApp ? 1 : 'auto')};
`;
