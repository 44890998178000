import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
const Door = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 15H9C9.55202 14.9994 9.99937 14.552 10 14V12.5H9V14H3V2H9V3.5H10V2C9.99937 1.44798 9.55202 1.00063 9 1H3C2.44798 1.00063 2.00063 1.44798 2 2V14C2.00063 14.552 2.44798 14.9994 3 15ZM10.293 10.293L12.086 8.5H5V7.5H12.086L10.293 5.707L11 5L14 8L11 11L10.293 10.293Z'
      fill='#DA1E28'
    />
  </Svg>
);
export default Door;
