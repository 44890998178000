import { StyledText } from '@gf/cross-platform-lib/components';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { View } from 'react-native';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { ProfileAccordionSubHeaderAction } from '../../profileInterfaces';
import { ChevronRight } from '@gf/cross-platform-lib/components/Icons/Carbonicons/collections';
import { DeleteAccountButton } from '../../../../../delete-account';
import { Fan } from '@gf/cross-platform-lib/models';

interface ProfileAccordionExpandableProps {
  subHeadersToActions: ProfileAccordionSubHeaderAction[];
}

export const ProfileAccordionExpandable = ({ subHeadersToActions }: ProfileAccordionExpandableProps) => {
  const email = Fan.getInstance().email;

  return subHeadersToActions.map(subHeaderToAction => {
    const AccordionExpandableItem = () => {
      return (
        <>
          <AccordionExpandable>
            <AccordionLeft>
              {!!subHeaderToAction.iconLeft ? subHeaderToAction.iconLeft : null}
              <StyledText typeToken='label02'>{subHeaderToAction.subHeader}</StyledText>
            </AccordionLeft>
            <ChevronRight height={16} width={16} fill={'#000000'} />
          </AccordionExpandable>
          <HorizontalRow marginTop={0} marginBottom={0} />
        </>
      );
    };
    return subHeaderToAction.subHeader === 'Delete account' ? (
      <DeleteAccountButton key={subHeaderToAction.subHeader} email={email}>
        <AccordionExpandableItem />
      </DeleteAccountButton>
    ) : (
      <TouchableOpacity key={subHeaderToAction.subHeader} onPress={subHeaderToAction.action} testID='test-sub-header'>
        <AccordionExpandableItem />
      </TouchableOpacity>
    );
  });
};

const AccordionExpandable = styled(View)`
  ${styles.accordionExpandable};
`;
const AccordionLeft = styled(View)`
  ${styles.accordionLeft};
`;
const HorizontalRow = styled(View)`
  ${styles.horizontalRow}
`;

export default ProfileAccordionExpandable;
