import React, { FunctionComponent } from 'react';

interface OwnProps {
  children: React.ReactNode;
  email: string;
}

type Props = OwnProps;

export const DeleteAccountButton: FunctionComponent<Props> = () => {
  return <>Do not support for web</>;
};
