import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const LetsChat = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 3a9 9 0 100 18h4.5c1.398 0 2.097 0 2.648-.228a3.002 3.002 0 001.624-1.624C21 18.597 21 17.898 21 16.5V12a9 9 0 00-9-9zm-4 8a1 1 0 011-1h6a1 1 0 010 2H9a1 1 0 01-1-1zm3 4a1 1 0 011-1h3a1 1 0 010 2h-3a1 1 0 01-1-1z'
        fill='#323232'
      />
    </Svg>
  );
};

export default LetsChat;
