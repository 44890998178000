import React from 'react';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { View } from 'react-native';
import { StyledText } from '../StyledText';
import { GetCurrentApplicationConfiguration, GetCurrentEnvironmentName } from '@gf/cross-platform-lib/utils';

export const AppInfoText = () => {
  const appConfig = GetCurrentApplicationConfiguration();
  const envName = GetCurrentEnvironmentName();

  return (
    <Info>
      <StyledText typeToken='label01' textAlign='center'>
        Copyright Ⓒ 2015-{new Date().getFullYear()} Huddle Tickets, LLC. All rights reserved.
      </StyledText>
      <StyledText typeToken='label01'>
        Version {appConfig.appVersion}:{appConfig.latestCommit ? appConfig.latestCommit.substring(0, 7) : '*'}:
        {envCodes[envName] || envName}
      </StyledText>
    </Info>
  );
};

const Info = styled(View)`
  ${styles.info}
`;

const envCodes: { [key: string]: string } = {
  dev1: 'D1',
  dev2: 'D2',
  dev3: 'D3',
  dev4: 'D4',
  dev5: 'D5',
  qa1: 'Q1',
  qa2: 'Q2',
  qa3: 'Q3',
  qa4: 'Q4',
  qa5: 'Q5',
  staging: 'S',
  perf: 'PF',
  production: 'P'
};

export default AppInfoText;
