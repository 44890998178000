import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  mobileContainer: css`
    padding-bottom: 64px;
    width: 100%;
  `,
  webContainer: css`
    margin-top: 16px;
  `,
  webBodyFooter: css`
    margin-horizontal: 40px;
  `,
  paddingWrapper: css`
    margin-horizontal: 32px;
  `
};
