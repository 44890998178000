import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  signOutButtonWrapper: css`
    flex-direction: row;
    border-color: #da1e28;
    border-width: 1px;
    padding: 11px;
    justify-content: center;
    border-radius: 4px;
    margin-vertical: 16px;
    align-items: center;
  `,
  iconWrapper: css`
    margin-left: 4px;
  `
};
