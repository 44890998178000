import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { ExternalLink, Link } from '@gf/cross-platform-lib/components';
import { Text, View } from 'react-native';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';

const GroupTitle = styled(Text)`
  width: auto;
  height: 20px;
  color: #161616;
  font-size: 14px;
  font-family: Rubik-SemiBold;
  font-weight: 600;
  letter-spacing: 0.16px;
  line-height: 20px;
`;

const LinkItem = styled(Text)`
  width: auto;
  height: 20px;
  color: #525252;
  font-size: 14px;
  font-family: Rubik-Regular;
  font-weight: normal;
  letter-spacing: 0.16px;
  line-height: 20px;
`;

export type PageLink = {
  href: string;
  title: string;
  isInternalLink?: boolean;
};

export type LinkGroupProps = {
  title: string;
  links: PageLink[];
};

export const aboutGroup: LinkGroupProps = {
  title: 'About',
  links: [
    { title: 'About us', href: 'https://get.gofan.co/company/about' },
    { title: 'News', href: 'https://get.gofan.co/blog' },
    { title: 'Careers', href: 'https://get.gofan.co/company/careers' }
  ]
};

export const schoolGroup: LinkGroupProps = {
  title: 'Schools',
  links: [
    { title: 'Sell tickets', href: 'https://get.gofan.co/' },
    { title: 'GoFan HQ', href: 'https://hq.gofan.co/' }
  ]
};

export const fanGroup: LinkGroupProps = {
  title: 'Fans',
  links: [
    { title: 'Fan support', href: 'http://fansupport.gofan.co/' },
    { title: 'All schools', href: GOFAN_APP_PAGES.allSchools.path, isInternalLink: true }
  ]
};

export const OutLinkGroup = ({ title, links }: LinkGroupProps) => {
  return (
    <View>
      <GroupTitle>{title}</GroupTitle>
      {links.map(link =>
        link.isInternalLink ? (
          <Link key={link.href} href={link.href} title={`Link to ${link.title}`}>
            <LinkItem>{link.title}</LinkItem>
          </Link>
        ) : (
          <ExternalLink key={link.href} href={link.href} title={`Link to ${link.title}`}>
            <LinkItem>{link.title}</LinkItem>
          </ExternalLink>
        )
      )}
    </View>
  );
};
