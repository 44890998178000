import React, { useState } from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './HeaderNav.styles';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from '@gf/cross-platform-lib/hooks';
import { Link } from '@gf/cross-platform-lib/components';

const Wrapper = styled(View)`
  ${styles.wrapper}
`;

const NavItem = styled(TouchableOpacity)`
  ${styles.navItem}
`;

const NavText = styled(Text)`
  ${styles.navText}
`;

export interface HeaderNavProp {
  signOut: () => Promise<void>;
  isFanSignedIn: boolean;
}

export const HeaderNav = ({ signOut, isFanSignedIn }: HeaderNavProp) => {
  const [isSignedIn, setFanIsSignedIn] = useState(isFanSignedIn);
  const navigate = useNavigate();

  React.useEffect(() => {
    setFanIsSignedIn(isFanSignedIn);
  }, [isFanSignedIn]);

  const signInItem = (
    <NavItem
      onPress={async () => {
        if (isSignedIn) {
          await signOut();
          setFanIsSignedIn(false);
          setTimeout(() => navigate(GOFAN_APP_PAGES.search.path), 300);
        } else {
          navigate(GOFAN_APP_PAGES.signIn.path);
        }
      }}
      testID='sign-in-sign-out'
    >
      <NavText>{isSignedIn ? 'Sign out' : 'Sign in'}</NavText>
    </NavItem>
  );

  return (
    <Wrapper>
      <Link href={GOFAN_APP_PAGES.myTickets.path}>
        <NavText>Tickets</NavText>
      </Link>
      <NavItem onPress={() => navigate(GOFAN_APP_PAGES.search.path)}>
        <NavText>Search schools</NavText>
      </NavItem>
      {signInItem}
    </Wrapper>
  );
};
