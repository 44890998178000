import React from 'react';
import { Text, View } from 'react-native';

interface FooterProps {}

export const DesktopFooter = ({}: FooterProps) => {
  return (
    <View>
      <Text style={{ color: 'red' }}>About About us Careers News Schools Sell tickets GoFan HQ Fans Fan support</Text>
    </View>
  );
};
