import React from 'react';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { TouchableOpacity, View } from 'react-native';
import { ChevronRight } from '@gf/cross-platform-lib/components/Icons/Carbonicons/collections';
import { StyledText } from '@gf/cross-platform-lib/components';
import { useCartState, useMediaQuery, useNavigate } from '@gf/cross-platform-lib/hooks';
import CartIcon from '@gf/cross-platform-lib/components/Icons/CartIcon';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { PROFILE, useDrawerContext } from '@gf/cross-platform-lib/providers';

export const ProfileCart = () => {
  const navigate = useNavigate();
  const { totalNumberOfTickets } = useCartState();
  const { isMobile } = useMediaQuery();
  const { closeDrawer } = useDrawerContext();

  return (
    <ProfilCartContainer
      onPress={() => {
        navigate(GOFAN_APP_PAGES.reviewAndBuy.path);
        !isMobile && closeDrawer(PROFILE);
      }}
    >
      <ProfileCartLeft>
        <CartIcon />
        <StyledText typeToken='heading02'>{totalNumberOfTickets}</StyledText>
      </ProfileCartLeft>
      <ProfileCartRight>
        <StyledText typeToken='label03'>View cart</StyledText>
        <ChevronRight width={16} height={16} fill={'#000000'} />
      </ProfileCartRight>
    </ProfilCartContainer>
  );
};

const ProfilCartContainer = styled(TouchableOpacity)`
  ${styles.profileCartContainer}
`;
const ProfileCartLeft = styled(View)`
  ${styles.profileCartLeft}
`;
const ProfileCartRight = styled(View)`
  ${styles.profileCartRight}
`;

export default ProfileCart;
