import { StyledText } from '@gf/cross-platform-lib/components';
import React from 'react';
import { View } from 'react-native';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';

interface ProfileAccordionHeaderProps {
  headerName: string;
}
export const ProfileAccordionHeader = ({ headerName }: ProfileAccordionHeaderProps) => {
  return (
    <MarginThirtyTwoTop>
      <StyledText typeToken='heading03SemiBold'>{headerName}</StyledText>
    </MarginThirtyTwoTop>
  );
};

const MarginThirtyTwoTop = styled(View)`
  ${styles.marginThirtyTwoTop};
`;

export default ProfileAccordionHeader;
