import React from 'react';
import { PlayOn, ExternalLink } from '@gf/cross-platform-lib/components';
import { PLAY_ON_SUPPORT_URL } from '@gf/cross-platform-lib/constants';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';
import { PlayOnFamily } from '../PlayOnFamily';

const Wrapper = styled.View`
  ${styles.wrapper};
`;

const VerticalLine = styled.View`
  ${styles.verticalLine};
`;

export const PlayOnBranding = ({ marginTop }: { marginTop?: string }) => {
  return (
    <Wrapper marginTop={marginTop}>
      <ExternalLink href={PLAY_ON_SUPPORT_URL} accessibilityLabel='Link to PlayOn' title='Link to PlayOn'>
        <PlayOn />
      </ExternalLink>
      <VerticalLine />
      <PlayOnFamily />
    </Wrapper>
  );
};
