import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  profileCartContainer: css`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-color: black;
    border-width: 1px;
    padding-vertical: 10px;
    padding-horizontal: 16px;
    border-radius: 4px;
    margin-top: 32px;
  `,
  profileCartLeft: css`
    flex-direction: row;
    gap: 4px;
    align-items: center;
  `,
  profileCartRight: css`
    flex-direction: row;
    align-items: center;
    gap: 8px;
  `
};
