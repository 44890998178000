import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  leading: css<{ isMobile: boolean }>`
    ${({ isMobile }) =>
      isMobile
        ? `position: absolute;
           top: 0;
           left: 0px;
           align-items: center;
           justify-content: center;
           z-index: 5;`
        : ''}
  `,
  wrapper: css<{ isMobile: boolean; headerSize: number }>`
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    text-align: center;
    background-color: #323232;
    height: ${({ headerSize }) => headerSize}px;
    color: white;
    z-index: 3;
    align-items: center;
  `,
  actionButtonsWrapper: css`
    position: absolute;
    top: 0;
    right: 16px;
    height: 44px;
    align-items: center;
    justify-content: center;
  `
};
