import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const TicketsTabIconFilled = (props: SvgProps) => (
  // @ts-ignore
  <Svg width={24} height={24} {...props} testID='tickets-tab-icon-filled-svg'>
    <Path
      d='M15.591 2.53035C14.7123 1.65167 13.2877 1.65167 12.409 2.53035L2.53035 12.409C1.65167 13.2877 1.65167 14.7123 2.53035 15.591L3.70189 16.7625C4.21258 17.2732 4.92945 17.1818 5.36261 16.9243C5.54862 16.8138 5.76564 16.7501 5.99983 16.7501C6.69019 16.7501 7.24983 17.3097 7.24983 18.0001C7.24983 18.2343 7.18614 18.4513 7.07558 18.6373C6.81811 19.0705 6.72667 19.7873 7.23736 20.298L8.40903 21.4697C9.28771 22.3484 10.7123 22.3484 11.591 21.4697L21.4697 11.591C22.3484 10.7123 22.3484 9.28771 21.4697 8.40903L20.2981 7.23742C19.7873 6.72667 19.0704 6.81819 18.6372 7.07574C18.4512 7.18635 18.2341 7.25008 17.9998 7.25008C17.3095 7.25008 16.7498 6.69043 16.7498 6.00008C16.7498 5.76583 16.8136 5.54875 16.9242 5.36271C17.1817 4.92955 17.2732 4.21258 16.7625 3.70183L15.591 2.53035Z'
      fill={props.fill}
    />
  </Svg>
);
