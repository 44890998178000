import React from 'react';
import { GFImage, Link } from '@gf/cross-platform-lib/components';
import { View } from 'react-native';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './Logo.styles';

interface LogoProps {
  isMobile?: boolean;
}

export const Logo = ({ isMobile }: LogoProps) => {
  return (
    <Wrapper isMobile={isMobile}>
      <Link href={'/'}>
        <GFImage width={137} height={44} name={'gofan-logo-white-mvp-svp.png'} alt='Gofan' />
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled(View)`
  ${styles.wrapper}
`;
