import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  marginTopTwentyFour: css`
    margin-top: 24px;
  `,
  marginBottomSixteen: css`
    margin-bottom: 16px;
  `,
  flexOne: css`
    flex: 1;
  `,
  centerWrapper: css`
    align-self: center;
    align-items: center;
  `,
  flexDirectionRow: css`
    flex-direction: row;
  `,
  container: css`
    flex-direction: column;
    margin-top: 24px;
  `,
  loggedInContainer: css`
    gap: 8px;
    flex-direction: row;
  `,
  iconWrapper: css`
    align-self: flex-start;
    justify-content: center;
  `,
  profileBodyContainer: css`
    margin-top: 16px;
  `,
  changePasswordWrapper: css`
    margin-left: 24px;
  `
};
