import React, { ReactNode } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './NavigationButton.styles';

interface BottomNavigationButtonProps {
  active: boolean;
  icon: ReactNode;
  label: string;
  testID?: string;
  accessibilityLabel?: string;
  onClick?: () => void;
}

interface NavigationButtonStyleProps {
  active: boolean;
  teamsInGoFanFlag: boolean;
}

export const NavigationButton = ({
  label,
  active,
  icon,
  testID,
  accessibilityLabel,
  onClick
}: BottomNavigationButtonProps) => {
  const launchDarklyContext = useLaunchDarklyContext();
  const teamsInGoFanFlag = launchDarklyContext.features['teams-in-gofan'].variation as boolean;

  return (
    <Wrapper
      onPress={onClick}
      testID={testID}
      accessibilityLabel={accessibilityLabel}
      active={active}
      teamsInGoFanFlag={teamsInGoFanFlag}
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Label active={active} teamsInGoFanFlag={teamsInGoFanFlag}>
        {label}
      </Label>
    </Wrapper>
  );
};

const Wrapper = styled(TouchableOpacity)<NavigationButtonStyleProps>`
  ${({ teamsInGoFanFlag = false }) => (teamsInGoFanFlag ? styles.wrapper : styles.wrapperLegacy)}
`;

const IconWrapper = styled(View)`
  ${styles.iconWrapper}
`;

const Label = styled(Text)<{ active: boolean; teamsInGoFanFlag: boolean }>`
  ${({ teamsInGoFanFlag = false }) => (teamsInGoFanFlag ? styles.label : styles.labelLegacy)}
`;
