import React from 'react';
import { PoweredByPlayOn, ExternalLink } from '@gf/cross-platform-lib/components';
import { PLAY_ON_SUPPORT_URL } from '@gf/cross-platform-lib/constants';

export const PlayOnFooter = () => {
  return (
    <ExternalLink href={PLAY_ON_SUPPORT_URL} accessibilityLabel='Link to PlayOn' title='Link to PlayOn'>
      <PoweredByPlayOn />
    </ExternalLink>
  );
};
