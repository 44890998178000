import React from 'react';
import { TouchableOpacity } from 'react-native';

import { HorizontalRow, StyledButton, StyledText } from '@gf/cross-platform-lib/components';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { useMediaQuery, useNavigate } from '@gf/cross-platform-lib/hooks';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { View } from 'react-native';
import { PROFILE, useDrawerContext } from '@gf/cross-platform-lib/providers';

export const ProfileBodySignedOut = () => {
  const navigate = useNavigate();
  const { isMobile } = useMediaQuery();
  const { closeDrawer } = useDrawerContext();

  return (
    <MarinTopTwentyFour>
      <MarginBottomSixteen>
        <StyledText typeToken='heading04'>Become a super fan</StyledText>
        <StyledText typeToken='label03'>
          Unlock your fan analytics, enjoy a quicker checkout, setup notifications when events start, and access your
          tickets quickly.
        </StyledText>
      </MarginBottomSixteen>
      <FlexDirectionRow>
        <FlexOne>
          <StyledButton
            width='100%'
            title='Sign in'
            accessibilityLabel='Sign in'
            onPress={() => {
              navigate(GOFAN_APP_PAGES.signIn.getPath());
              !isMobile && closeDrawer(PROFILE);
            }}
          />
        </FlexOne>
      </FlexDirectionRow>
      <FlexDirectionRow>
        <HorizontalRow marginBottom={16} marginTop={16} />
      </FlexDirectionRow>
      <CenterWrapper alignSelf='center' alignItems='center'>
        <MarginBottomSixteen>
          <StyledText typeToken='helperText02'>Don&apos;t have a GoFan account?</StyledText>
        </MarginBottomSixteen>
        <TouchableOpacity
          onPress={() => {
            navigate(GOFAN_APP_PAGES.signUp.getPath());
            !isMobile && closeDrawer(PROFILE);
          }}
        >
          <StyledText textDecoration='underline' typeToken='bodyCompact02' color='#0F62FE'>
            Sign up
          </StyledText>
        </TouchableOpacity>
      </CenterWrapper>
    </MarinTopTwentyFour>
  );
};

const MarinTopTwentyFour = styled(View)`
  ${styles.marginTopTwentyFour}
`;
const MarginBottomSixteen = styled(View)`
  ${styles.marginBottomSixteen}
`;
const FlexOne = styled(View)`
  ${styles.flexOne}
`;
const CenterWrapper = styled(View)`
  ${styles.centerWrapper}
`;
const FlexDirectionRow = styled(View)`
  ${styles.flexDirectionRow}
`;

export default ProfileBodySignedOut;
