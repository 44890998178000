import React, { useState, useEffect } from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { NavigationButton } from './NavigationButton';
import {
  HomeIcon,
  HomeIconFilled,
  MenuIcon,
  FavoritesIcon,
  FavoritesIconFilled,
  TicketsTabIcon,
  TicketsTabIconFilled
} from '@gf/cross-platform-lib/components';
import { useRouter, useNavigate, useFetchFavoriteSchoolsById, useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { styles } from './MobileFooter.styles';
import { useSearchPageContext } from '@gf/cross-platform-lib/providers';
import { MotiView as View } from 'moti';
import { bottomNavigator, testProperties, abbreviateWords } from '@gf/cross-platform-lib/utils';
import { Keyboard } from 'react-native';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers';
import { Fan } from '@gf/cross-platform-lib/models';
import { SchoolLogo } from '@gf/cross-platform-lib/components/SchoolLogo';

interface FooterProps {}

interface SchoolSiteUrls {
  [schoolId: string]: {
    src: string;
  };
}

interface SchoolTabData {
  url: string;
  logo: string | undefined;
  name: string;
  schoolColorPrimary: string | undefined;
  schoolColorSecondary: string | undefined;
  mascot: string | undefined;
}

export const MobileFooter = ({}: FooterProps) => {
  const navigate = useNavigate();
  const { footerVisible } = useSearchPageContext();
  const { currentRoute } = useRouter();

  const isActive = (path: string) => currentRoute === path;
  const activeColor = (path: string) => (isActive(path) ? '#4A4A4B' : '#191919');

  const [currentTab, setCurrentTab] = useState(currentRoute);
  const [schoolTabData, setSchoolTabData] = useState<SchoolTabData | null>(null);
  const launchDarklyContext = useLaunchDarklyContext();

  const fan = Fan.getInstance();

  const [favSchools, setFavSchools] = useState<string[]>(fan.favorSchoolIds);

  const { favoritedSchools } = useFetchFavoriteSchoolsById([...favSchools]);
  const schoolIdsToSiteUrls = launchDarklyContext.features['teams-sites-promotion'].variation as SchoolSiteUrls;
  const favoriteSchoolTabEnabled = launchDarklyContext.features['enable-favorite-school-tab'].variation;
  const { isNativeApp } = useMediaQuery();

  useEffect(() => {
    // Currently working from LaunchDarkly - once Org Service is active,
    // this logic will need to change to check if the school has a PlayOn Site
    // and if the site_visibility flag from org service is true
    const validSchoolId = favSchools.reverse().find(schoolId => schoolIdsToSiteUrls[schoolId]);

    if (validSchoolId) {
      const schoolData = favoritedSchools[validSchoolId];

      setSchoolTabData({
        url: schoolIdsToSiteUrls[validSchoolId].src,
        logo: schoolData?.logoUrl,
        schoolColorPrimary: schoolData?.primaryColor,
        schoolColorSecondary: schoolData?.secondaryColor,
        mascot: schoolData?.mascot,
        name: abbreviateWords(schoolData?.name)
      });
    } else {
      setSchoolTabData(null);
    }
  }, [favSchools, schoolIdsToSiteUrls]);

  useEffect(() => {
    const updateFavSchools = () => {
      const newFavSchools = fan.favorSchoolIds;
      if (newFavSchools.length !== favSchools.length || !newFavSchools.every(id => favSchools.includes(id))) {
        setFavSchools(newFavSchools);
      }
    };

    updateFavSchools();
    const intervalId = setInterval(updateFavSchools, 1000);

    return () => clearInterval(intervalId);
  }, [favSchools]);

  return (
    <Wrapper
      animate={{
        translateY: footerVisible ? 0 : 44
      }}
      transition={{
        type: 'timing',
        duration: 250
      }}
    >
      <Footer>
        <NavigationButton
          onClick={async () => {
            setCurrentTab(currentRoute);
            await navigate('/');
          }}
          label='Home'
          icon={
            currentTab === GOFAN_APP_PAGES.home.path ? (
              <HomeIconFilled color={activeColor('/')} />
            ) : (
              <HomeIcon color={activeColor('/')} />
            )
          }
          active={isActive('/')}
          {...testProperties(bottomNavigator.home)}
        />
        <NavigationButton
          label='Tickets'
          onClick={() => {
            setCurrentTab(currentRoute);
            navigate(GOFAN_APP_PAGES.myTickets.path);
          }}
          icon={
            currentTab === GOFAN_APP_PAGES.myTickets.path ? (
              <TicketsTabIconFilled color={activeColor(GOFAN_APP_PAGES.myTickets.path)} />
            ) : (
              <TicketsTabIcon color={activeColor(GOFAN_APP_PAGES.myTickets.path)} />
            )
          }
          active={isActive(GOFAN_APP_PAGES.myTickets.path)}
          {...testProperties(bottomNavigator.tickets)}
        />
        <NavigationButton
          onClick={async () => {
            setCurrentTab(currentRoute);
            await navigate(GOFAN_APP_PAGES.mySchool.path);
          }}
          label='Favorites'
          icon={
            currentTab === GOFAN_APP_PAGES.mySchool.path ? (
              <FavoritesIconFilled color={activeColor(GOFAN_APP_PAGES.mySchool.path)} />
            ) : (
              <FavoritesIcon color={activeColor(GOFAN_APP_PAGES.mySchool.path)} />
            )
          }
          active={isActive(GOFAN_APP_PAGES.mySchool.path)}
          {...testProperties(bottomNavigator.schools)}
        />
        {schoolTabData && favoriteSchoolTabEnabled && isNativeApp && schoolTabData?.logo && (
          <NavigationButton
            label={schoolTabData?.name || ''}
            onClick={() => {
              setCurrentTab(currentRoute);
              const encodedUrl = encodeURIComponent(schoolTabData?.url || '');
              navigate(
                `${GOFAN_APP_PAGES.favoriteSchoolWebview.path}?url=${encodedUrl}&schoolColorPrimary=${schoolTabData.schoolColorPrimary}&schoolColorSecondary=${schoolTabData.schoolColorSecondary}`
              );
            }}
            icon={
              <SchoolLogo
                logoSrc={schoolTabData.logo || ''}
                schoolColor={`#${schoolTabData.schoolColorPrimary}`}
                logoHeight={16}
                logoWidth={16}
                logoPadding={4}
              />
            }
            active={isActive(GOFAN_APP_PAGES['web-view'].path)}
            {...testProperties(bottomNavigator.favoriteSchool)}
          />
        )}
        <NavigationButton
          label='Profile'
          onClick={() => {
            Keyboard.dismiss();
            setCurrentTab(currentRoute);
            navigate(GOFAN_APP_PAGES.profile.path);
          }}
          icon={<MenuIcon />}
          active={false}
          {...testProperties(bottomNavigator.more)}
        />
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled(View)`
  ${styles.wrapper}
`;
const Footer = styled(View)`
  ${styles.footer}
`;
