import React from 'react';
import { GoFanLogo, ExternalLink, NFHSLogo, RSchoolTodayLogo } from '@gf/cross-platform-lib/components';
import { GOFAN_URL, NFHS_URL, R_SCHOOL_TODAY_URL } from '@gf/cross-platform-lib/constants';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';

const Wrapper = styled.View`
  ${styles.wrapper};
`;

const PlayOnFamilyWrapper = styled.View`
  ${styles.playOnFamilyWrapper};
`;

const Title = styled.Text`
  ${styles.title};
`;

export const PlayOnFamily = ({ isInline = false }: { isInline?: boolean }) => {
  return (
    <Wrapper isInline={isInline}>
      <Title>GoFan is part of the PlayOn family of brands</Title>
      <PlayOnFamilyWrapper>
        <ExternalLink href={GOFAN_URL} accessibilityLabel='Link to Gofan' title='Link to Gofan'>
          <GoFanLogo width={isInline ? 59 : 110} height={isInline ? 12 : 22} />
        </ExternalLink>
        <ExternalLink href={NFHS_URL} accessibilityLabel='Link to NFHS' title='Link to NFHS'>
          <NFHSLogo width={isInline ? 33 : 50} height={isInline ? 16 : 24} />
        </ExternalLink>
        <ExternalLink href={R_SCHOOL_TODAY_URL} accessibilityLabel='Link to rSchoolToday' title='Link to rSchoolToday'>
          <RSchoolTodayLogo width={isInline ? 63 : 104} height={isInline ? 13 : 22} />
        </ExternalLink>
      </PlayOnFamilyWrapper>
    </Wrapper>
  );
};
