import * as React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const SchoolIcon = (props: SvgProps) => (
  // @ts-ignore
  <Svg width={23} height={23} xmlns='http://www.w3.org/2000/svg' {...props}>
    <G fill={props.color} fillRule='nonzero'>
      <Path d='M22.19 11.249h-4.373V8.272L11.5 3.152 5.183 8.267v2.976H.81c-.447 0-.81.376-.81.84V22.16c0 .464.363.84.81.84h7.775v-3.185c0-1.948 1.308-3.534 2.915-3.534s2.915 1.586 2.915 3.534V23h7.775c.447 0 .81-.376.81-.84V12.09c0-.464-.363-.84-.81-.84zM6.155 18.136c0 .275-.22.504-.486.504H3.077a.499.499 0 0 1-.485-.504v-2.352c0-.275.22-.504.485-.504H5.67c.266 0 .486.229.486.504v2.352zm5.345-5.819c-1.607 0-2.915-1.357-2.915-3.023 0-1.667 1.308-3.024 2.915-3.024s2.915 1.357 2.915 3.024c0 1.666-1.308 3.023-2.915 3.023zm8.908 5.819c0 .275-.22.504-.485.504H17.33a.499.499 0 0 1-.486-.504v-2.352c0-.275.22-.504.486-.504h2.592c.265 0 .485.229.485.504v2.352z' />
      <Path d='M11.5 7.271a.327.327 0 0 0-.324.336v1.35h-1.075a.327.327 0 0 0-.324.337c0 .188.142.336.324.336h1.723V7.607a.327.327 0 0 0-.324-.336z' />
      <Path d='M4.386 7.661 11.5 1.896l7.114 5.765c.149.121.324.175.499.175.24 0 .48-.114.641-.323a.857.857 0 0 0-.142-1.176L11.999.177a.794.794 0 0 0-.998 0L3.388 6.343a.863.863 0 0 0-.142 1.176c.279.363.79.43 1.14.141z' />
    </G>
  </Svg>
);
