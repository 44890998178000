import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import Door from '../Icons/Door';
import { StyledText } from '../StyledText';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';
import { useAuthentication, useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { PROFILE, useDrawerContext } from '@gf/cross-platform-lib/providers';

export const SignOut = () => {
  const { signOut } = useAuthentication();
  const { isMobile } = useMediaQuery();
  const { closeDrawer } = useDrawerContext();

  return (
    <SignOutButtonWrapper
      onPress={() => {
        signOut();
        !isMobile && closeDrawer(PROFILE);
      }}
      testID='test-profile-sign-out'
    >
      <StyledText color='#DA1E28'>Sign out</StyledText>
      <IconWrapper>
        <Door />
      </IconWrapper>
    </SignOutButtonWrapper>
  );
};
const IconWrapper = styled(View)`
  ${styles.iconWrapper}
`;
const SignOutButtonWrapper = styled(TouchableOpacity)`
  ${styles.signOutButtonWrapper}
`;

export default SignOut;
