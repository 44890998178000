import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `,
  closeWrapper: css`
    align-items: center;
    flex-direction: row;
    gap: 4px;
  `
};
