import React from 'react';

import { Link, StyledText } from '@gf/cross-platform-lib/components';
import { CheckmarkFilled } from '@gf/cross-platform-lib/components/Icons/Carbonicons/collections';
import { Fan } from '@gf/cross-platform-lib/models';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { View } from 'react-native';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';

export const ProfileBodySignedIn = () => {
  const fan = Fan.getInstance();

  return (
    <Container>
      <LoggedInContainer>
        <IconWrapper>
          <CheckmarkFilled height={16} width={16} fill='#000000' />
        </IconWrapper>
        <StyledText typeToken='body01'>
          You&apos;re logged in as <StyledText typeToken='headingCompact01'>{fan.email}</StyledText>
        </StyledText>
      </LoggedInContainer>
      <ChangePasswordWrapper>
        <Link href={GOFAN_APP_PAGES.changePassword.path}>
          <StyledText typeToken='body01' color='#757575'>
            Change password
          </StyledText>
        </Link>
      </ChangePasswordWrapper>
    </Container>
  );
};

const Container = styled(View)`
  ${styles.container}
`;
const LoggedInContainer = styled(View)`
  ${styles.loggedInContainer}
`;
const IconWrapper = styled(View)`
  ${styles.iconWrapper}
`;
const ChangePasswordWrapper = styled(View)`
  ${styles.changePasswordWrapper}
`;

export default ProfileBodySignedIn;
