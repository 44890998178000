import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  wrapper: css<{ isInline: boolean }>`
    gap: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    ${({ isInline }) =>
      isInline &&
      `
        flex-direction: row;
        padding: 16px;
        gap: 24px;
        max-width: 530px;
        height: 80px;
        align-self: center;
      `};
    background-color: #f6f6fa;
  `,
  playOnFamilyWrapper: css`
    gap: 24px;
    display: flex;
    align-items: center;
    flex-direction: row;
  `,
  title: css`
    color: #323232;
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 12px;
  `
};
