import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const TicketIcon = (props: SvgProps) => (
  // @ts-ignore
  <Svg width={24} height={24} viewBox='0 0 26 26' xmlns='http://www.w3.org/2000/svg' {...props}>
    <Path
      d='m14.823.971-1.14 1.14-2.292 2.293-2.683 2.683L6.357 9.44l-1.263 1.263-.057.057a.273.273 0 0 0 0 .383c.285.296.29.78.009 1.081-.305.326-.788.312-1.1.01a.276.276 0 0 0-.386 0l-2.4 2.404c-.274.275-.532.55-.622.948-.12.53.046 1.068.43 1.45l.295.297 1.401 1.401c.674.676 1.35 1.35 2.026 2.026l2.104 2.105 1.61 1.61c.272.272.549.617.883.815.603.361 1.38.245 1.873-.244.201-.199.397-.397.598-.598l1.862-1.863.13-.13a.276.276 0 0 0 0-.383.778.778 0 0 1 .006-1.086.78.78 0 0 1 1.086-.006c.106.1.277.106.383 0l1.121-1.122 2.632-2.631 3.038-3.04 2.362-2.362c.196-.196.394-.391.59-.59.03-.03.06-.057.086-.086.372-.383.527-.927.405-1.45a1.526 1.526 0 0 0-.394-.704l-.01-.011-3.435-3.436-2.632-2.631-1.76-1.76c-.17-.171-.333-.348-.545-.467-.619-.34-1.368-.207-1.86.282ZM12.39 18.855a.554.554 0 0 1 0 .769c-.198.217-.567.2-.768 0-.215-.217-.432-.432-.646-.647L8.149 16.15l-1.773-1.773a.554.554 0 0 1 0-.769c.198-.217.568-.2.768 0 .215.217.432.432.647.647l2.826 2.827 1.773 1.773Zm7.223-9.88c.217.198.2.567 0 .768l-.682.682-2.99 2.99-1.875 1.877a.554.554 0 0 1-.769 0c-.217-.198-.2-.568 0-.769l.682-.681 2.99-2.99c.626-.625 1.251-1.252 1.878-1.877.212-.215.549-.198.766 0Zm-4.564-.617c.217.199.2.568 0 .769l-.443.443-1.928 1.928-1.203 1.203a.554.554 0 0 1-.768 0c-.217-.198-.201-.568 0-.769l.442-.442c.644-.644 1.287-1.288 1.931-1.928l1.203-1.204c.212-.214.548-.198.766 0Z'
      fill={props.color || '#323232'}
      fillRule='nonzero'
    />
  </Svg>
);
