import uniq from 'lodash/uniq';
import { useFetchSchools } from '@gf/cross-platform-lib/modules/AcquisitionV2/queries/schoolQueries';
import { SchoolInterface } from '@gf/cross-platform-lib/interfaces/School';

export const useFetchFavoriteSchoolsById = (schoolIds: string[]) => {
  const uniqueSchoolIds = uniq(schoolIds);
  const favoritedSchoolsQuery = useFetchSchools(uniqueSchoolIds);

  const favoritedSchools = {} as { [key: string]: SchoolInterface };
  favoritedSchoolsQuery.forEach(school => {
    school.data?.data && (favoritedSchools[school.data?.data.huddleId] = school.data?.data);
  });

  return {
    favoritedSchools
  };
};
