import React from 'react';
import ProfileBodySignedIn from './ProfileBodySignedIn';
import ProfileBodySignedOut from './ProfileBodySignedOut';
import { ProfileAccordionMenu } from '../ProfileAccordionMenu';
import { SignOut } from '@gf/cross-platform-lib/components';
// TO DO: UNCOMMENT FOR WHEN ENDPOINT IS READY
// import { ProfileFandom } from '../ProfileFandom';
import { ProfileCart } from '../ProfileCart';
// TO DO: UNCOMMENT FOR WHEN ENDPOINT IS READY
// import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';

interface ProfileBodyProps {
  email: string;
}

export const ProfileBody = ({ email }: ProfileBodyProps) => {
  // TO DO: UNCOMMENT FOR WHEN ENDPOINT IS READY
  // const { isNativeApp } = useMediaQuery();
  // const navigate = useNavigate();

  return (
    <>
      {<ProfileCart />}
      {!!email ? <ProfileBodySignedIn /> : <ProfileBodySignedOut />}
      {/* {isNativeApp && (
        <ProfileBodyContainer
          disabled={!email}
          onPress={() => navigate(GOFAN_APP_PAGES.fandom.path)}
          testID='test-view-activity'
        >
          <ProfileFandom signedInAndNative={!!email && isNativeApp} showAchievements={false} />
        </ProfileBodyContainer>
      )} */}
      <ProfileAccordionMenu />
      {!!email && <SignOut />}
      {/* TO DO: UNCOMMENT FOR WHEN ENDPOINT IS READY
        {!isNativeApp && !!email && <ProfileFandom 
        signedInAndNative={!!email && isNativeApp} showAchievements={false} />} */}
    </>
  );
};

// TO DO: UNCOMMENT FOR WHEN ENDPOINT IS READY
// const ProfileBodyContainer = styled(TouchableOpacity)`
//   ${styles.profileBodyContainer}
// `;

export default ProfileBody;
