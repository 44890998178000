import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { NavigationButton } from '../NavigationButton';
import { HomeIconLegacy, MenuIconLegacy, SchoolIcon, TicketIcon } from '@gf/cross-platform-lib/components';
import { useRouter, useNavigate } from '@gf/cross-platform-lib/hooks';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { legacyStyles } from '../MobileFooter.styles';
import { useSearchPageContext } from '@gf/cross-platform-lib/providers';
import { MotiView as View } from 'moti';
import { bottomNavigator, testProperties } from '@gf/cross-platform-lib/utils';
import { Keyboard } from 'react-native';

interface FooterProps {}

export const MobileFooterLegacy = ({}: FooterProps) => {
  const navigate = useNavigate();
  const { footerVisible } = useSearchPageContext();
  const { currentRoute } = useRouter();
  const isActive = (path: string) => currentRoute === path;
  const activeColor = (path: string) => (isActive(path) ? '#282828' : '#a8a8a8');

  return (
    <Wrapper
      animate={{
        translateY: footerVisible ? 0 : 62
      }}
      transition={{
        type: 'timing',
        duration: 250
      }}
    >
      <NavigationButton
        onClick={() => navigate('/')}
        label='Home'
        icon={<HomeIconLegacy color={activeColor('/')} />}
        active={isActive('/')}
        {...testProperties(bottomNavigator.home)}
      />
      <NavigationButton
        onClick={() => navigate(GOFAN_APP_PAGES.mySchool.path)}
        label='Schools'
        icon={<SchoolIcon color={activeColor(GOFAN_APP_PAGES.mySchool.path)} />}
        active={isActive(GOFAN_APP_PAGES.mySchool.path)}
        {...testProperties(bottomNavigator.schools)}
      />
      <NavigationButton
        label='Tickets'
        onClick={() => navigate(GOFAN_APP_PAGES.myTickets.path)}
        icon={<TicketIcon color={activeColor(GOFAN_APP_PAGES.myTickets.path)} />}
        active={isActive(GOFAN_APP_PAGES.myTickets.path)}
        {...testProperties(bottomNavigator.tickets)}
      />
      <NavigationButton
        label='More'
        onClick={() => {
          Keyboard.dismiss();
          navigate(GOFAN_APP_PAGES.profile.path);
        }}
        icon={<MenuIconLegacy />}
        active={false}
        {...testProperties(bottomNavigator.more)}
      />
    </Wrapper>
  );
};

const Wrapper = styled(View)`
  ${legacyStyles.footer}
`;
