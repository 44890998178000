import React from 'react';

import { LogoBlack, StyledText } from '@gf/cross-platform-lib/components';
import { CloseFilled } from '@gf/cross-platform-lib/components/Icons/Carbonicons/collections';
import { TouchableOpacity } from 'react-native';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { View } from 'react-native';
import { PROFILE, useDrawerContext } from '@gf/cross-platform-lib/providers';

export const ProfileHeader = () => {
  const { closeDrawer } = useDrawerContext();

  return (
    <Container alignItems='center' flexDirection='row' justifyContent='space-between'>
      <LogoBlack />
      <TouchableOpacity onPress={() => closeDrawer(PROFILE)}>
        <CloseWrapper>
          <StyledText typeToken='helperText01'>Close</StyledText>
          <CloseFilled fill='#000000' height={24} width={24} />
        </CloseWrapper>
      </TouchableOpacity>
    </Container>
  );
};

const Container = styled(View)`
  ${styles.container}
`;
const CloseWrapper = styled(View)`
  ${styles.closeWrapper}
`;

export default ProfileHeader;
