import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  wrapper: css<{ marginTop: string }>`
    margin-top: ${({ marginTop }) => marginTop || '32px'};
    padding: 32px 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f6f6fa;
  `,
  verticalLine: css`
    width: 320px;
    border: 0.5px solid #c6c6ca;
  `
};
